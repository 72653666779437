import React from "react";
import { Form, Card, Row, Container, Col, Button, ProgressBar, Table, Modal, } from 'react-bootstrap';
import update from 'immutability-helper';
import { WidgetWrapper, Section, BrandedComponentProps } from '@innexgo/common-react-components';
import DashboardLayout from '../components/DashboardLayout';

import { ArticleData, articleDataViewPublic } from '../utils/api';
import { AuthenticatedComponentProps } from '@innexgo/auth-react-components';

import { randn_bm } from '../utils/normal';

import Plot from 'react-plotly.js';
import { Formik, ErrorMessage, FormikHelpers, FormikErrors } from "formik";


type HistoryEntry = {
  betOnHeads: boolean,
  heads: boolean,
  proportionBet: number,
}

type GameState = {
  initialPool: number,
  headsOdds: number,
  rounds: number,
  history: HistoryEntry[],
}

type GameProps = {
  state: GameState,
  onSelect: (e: HistoryEntry) => void,
  onReset: () => void,
  onNewGame: () => void,
}

function round(x: number): number {
  return Math.floor(x * 100) / 100
}

function Game(props: GameProps) {
  // recalculate score

  const moneys = [props.state.initialPool];
  const dMoneys = [0];

  for (const entry of props.state.history) {
    const lastMoney = moneys[moneys.length - 1];
    if (entry.heads === entry.betOnHeads) {
      moneys.push(lastMoney + entry.proportionBet * lastMoney);
    } else {
      moneys.push(Math.max(lastMoney - entry.proportionBet * lastMoney, 0))
    }
    dMoneys.push(moneys[moneys.length - 1] - lastMoney);
  }

  type BetValue = {
    heads?: boolean,
    percentage?: number,
  }

  const percent = 100 * (props.state.history.length / props.state.rounds);

  return <>
    <Modal
      centered
      backdrop="static"
      keyboard={false}
      show={props.state.history.length >= props.state.rounds || moneys[moneys.length - 1] <= 0}
    >
      <Modal.Header><h5>Game Over!</h5></Modal.Header>
      <Modal.Body>
        <h6 className="mt-3">Final Score</h6>
        <p className="text-success"><b>${round(moneys[moneys.length - 1])}</b></p>
        <p className={moneys[moneys.length - 1] > moneys[0] ? "text-success" : "text-danger"}><b>
          {Math.floor(100 * moneys[moneys.length - 1] / moneys[0])}% of original ${moneys[0]}
        </b></p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={props.onReset}>Reset Game</Button>
        <Button variant="primary" onClick={props.onNewGame}>Start New Game</Button>
      </Modal.Footer>
    </Modal>
    <WidgetWrapper title="Kelly Betting Game">
      <span>Shows basic information about this game.</span>
      <Row>
        <Col xl={8} >
          <h5 className="mt-3">Coin Bias</h5>
          <p className="text-success"><b>{props.state.headsOdds*100}% chance of heads</b></p>
          <h5 className="mt-3">Rounds Left</h5>
          <p className="text-danger"><b>{props.state.rounds - props.state.history.length} rounds left</b></p>
          <ProgressBar className="mt-3" variant="danger" now={Math.max(percent, 10)} label={`${Math.ceil(percent)}%`} />
          <h5 className="mt-3">Money So Far</h5>
          <p className="text-success"><b>${round(moneys[moneys.length - 1])}</b></p>
          <h5 className="mt-3">Money Graph</h5>
          <Plot
            className="mx-2"
            data={[
              {
                type: 'scatter',
                x: [...Array(moneys.length)].map((_, i) => i),
                y: moneys,
                mode: 'lines+markers',
                marker: { color: 'red' },
              },
            ]}
            layout={{
              xaxis: { title: { text: 'Round', }, },
              yaxis: { title: { text: 'Money', } },
              width: 600,
              height: 200,
              margin: { b: 40, l: 50, r: 10, t: 10 },
            }}
            config={{ staticPlot: true }}
          />
        </Col>
        <Col sm={4}>
          <h6>Explanation</h6>
          <div>
            You start off with a fixed pool of money that you can bet.
            In each round you're able to bet a given percentage of that pool on a coin coming up heads or tails.
            Luckily for you, the coin is weighted in your favor!
            However, there's still the risk that the coin could come up tails, and you lose the bet.
            So, you have to balance risk management and money acquisition.
            Bet too little, and you won't make as much money as you could have.
            Bet too much, and you could lose all of it.
          </div>
        </Col>
      </Row>
    </WidgetWrapper>
    <div className="mt-5">
      <h5>Bet</h5>
      <Formik<BetValue>
        onSubmit={(values, fprops) => {

          let hasError = false;
          let errors: FormikErrors<BetValue> = {};

          if (values.heads === undefined) {
            errors.heads = "Choose whether to bet on heads or tails";
            hasError = true;
          }

          if (values.percentage === undefined || isNaN(values.percentage)) {
            errors.percentage = "Enter in the percentage you want to bet";
            hasError = true;
          } else if (values.percentage < 0 || values.percentage > 100) {
            errors.percentage = "Value out of bounds";
            hasError = true;
          }

          fprops.setErrors(errors);
          if (hasError) {
            return;
          }

          props.onSelect({
            betOnHeads: values.heads!,
            heads: Math.random() < props.state.headsOdds,
            proportionBet: (values.percentage!) / 100
          });
        }}
        initialValues={{}}

      >
        {(fprops) => <Form noValidate onSubmit={fprops.handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Check
              label="Bet on Heads"
              type="radio"
              isInvalid={!!fprops.errors.heads}
              onChange={e => fprops.setFieldValue("heads", true)}
              checked={fprops.values.heads === true}
            />
            <Form.Check>
              <Form.Check.Input
                type="radio"
                checked={fprops.values.heads === false}
                isInvalid={!!fprops.errors.heads}
                onChange={e => fprops.setFieldValue("heads", false)}
              />
              <Form.Check.Label>Bet on Tails</Form.Check.Label>
              <Form.Control.Feedback type="invalid">{fprops.errors.heads}</Form.Control.Feedback>
            </Form.Check>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label >Percentage to Bet</Form.Label>
            <Form.Control
              type="text"
              name="percentage"
              value={fprops.values.percentage}
              onChange={fprops.handleChange}
              isInvalid={!!fprops.errors.percentage}
            />
            <Form.Control.Feedback type="invalid">{fprops.errors.percentage}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Button type="submit" style={{ display: "block" }}>
              Bet!
            </Button>
          </Form.Group>
        </Form>
        }
      </Formik>
    </div>
    <div className="mt-5">
      <h5>History</h5>
      <Table bordered striped hover>
        <thead>
          <tr><th>Bet On</th><th>Result</th><th>Bet Percentage</th><th>Money Gained</th><th>Current Money</th></tr>
        </thead>
        <tbody>
          {
            props.state.history.map((h, i) => <tr key={i}>
              <td>{h.betOnHeads ? "heads" : "tails"}</td>
              <td>{h.heads ? "heads" : "tails"}</td>
              <td>{round(h.proportionBet * 100)}%</td>
              <td className={dMoneys[i + 1] > 0 ? "text-success" : "text-danger"}>{round(dMoneys[i + 1])}</td>
              <td>${round(moneys[i + 1])}</td>
            </tr>
            ).reverse()
          }
        </tbody>
      </Table>
    </div>
  </>
}


type GameSetupProps = {
  onInitialize: (state: GameState) => void,
}

function GameSetup(props: GameSetupProps) {
  type GameSetupValue = {
    initialPool: number,
    headsOdds: number,
    rounds: number,
  }

  const onSubmit = (values: GameSetupValue, fprops: FormikHelpers<GameSetupValue>) => {
    let hasError = false;
    let errors: FormikErrors<GameSetupValue> = {};

    if (isNaN(values.initialPool) || values.initialPool <= 0 || values.initialPool >= 1000) {
      errors.headsOdds = "Value out of bounds";
      hasError = true;
    }

    if (isNaN(values.headsOdds) || values.headsOdds < 0 || values.headsOdds > 100) {
      errors.headsOdds = "Value out of bounds";
      hasError = true;
    } else if (values.headsOdds < 50) {
      errors.headsOdds = "The coin must be biased in your favor";
      hasError = true;
    }

    if (values.rounds % 1 !== 0) {
      errors.rounds = "Value must be integer";
      hasError = true;
    }
    if (isNaN(values.headsOdds) || values.rounds <= 0 || values.rounds > 100) {
      errors.rounds = "Value out of bounds";
      hasError = true;
    }

    fprops.setErrors(errors);
    if (hasError) {
      return;
    }

    props.onInitialize({
      initialPool: values.initialPool,
      headsOdds: values.headsOdds / 100,
      rounds: values.rounds,
      history: []
    });
  }
  return <div className="h-100 w-100 d-flex">
    <Card className="mx-auto my-auto col-md-6">
      <Card.Body>
        <Card.Title>Kelly Betting Game Setup</Card.Title>
        <Formik<GameSetupValue>
          onSubmit={onSubmit}
          initialValues={{
            initialPool: 10,
            headsOdds: 60,
            rounds: 50,
          }}
        >
          {(fprops) => <Form noValidate onSubmit={fprops.handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Initial Betting Pool</Form.Label>
              <Form.Control
                type="number"
                onChange={fprops.handleChange}
                value={fprops.values.initialPool}
                name="initialPool"
                isInvalid={!!fprops.errors.initialPool}
              />
              <Form.Control.Feedback type="invalid">{fprops.errors.initialPool}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Percentage Coin Flip is Heads</Form.Label>
              <Form.Control
                type="number"
                onChange={fprops.handleChange}
                value={fprops.values.headsOdds}
                name="headsOdds"
                isInvalid={!!fprops.errors.headsOdds}
              />
              <Form.Control.Feedback type="invalid">{fprops.errors.headsOdds}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Number of Rounds</Form.Label>
              <Form.Control
                type="number"
                onChange={fprops.handleChange}
                value={fprops.values.rounds}
                name="rounds"
                isInvalid={!!fprops.errors.rounds}
              />
              <Form.Control.Feedback type="invalid">{fprops.errors.rounds}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Button type="submit" style={{ display: "block" }}>
                Start Game!
              </Button>
              <Form.Text muted>Good Luck!</Form.Text>
            </Form.Group>
          </Form>
          }
        </Formik>
      </Card.Body>
    </Card>
  </div>
}

export default function KellyBettingGame(props: AuthenticatedComponentProps) {
  let [state, setState] = React.useState<GameState | null>(null);

  return <DashboardLayout {...props}>
    <Container className="px-4 py-4">
      {
        state === null
          ? <GameSetup onInitialize={(state) => setState(state)} />
          : <Game
            state={state}
            onSelect={entry => setState(update(state, { history: { $push: [entry] } }))}
            onReset={() => setState(update(state, { history: { $set: [] } }))}
            onNewGame={() => setState(null)}
          />
      }
    </Container>
  </DashboardLayout>
}

