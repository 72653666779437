import React from 'react';
import {
    ColumnsGap as DashboardIcon,
    CalendarEvent as  CalendarIcon,
    Gear as SettingsIcon,
    Star as GameIcon,
    Person as AccountIcon
} from 'react-bootstrap-icons';
import { InnerLayout, AuthenticatedComponentProps } from '@innexgo/auth-react-components';

export default function DashboardLayout(props: React.PropsWithChildren<AuthenticatedComponentProps>) {
  return <InnerLayout apiKey={props.apiKey} logoutCallback={() => props.setApiKey(null)} >
    <InnerLayout.SidebarEntry label="Dashboard" icon={DashboardIcon} href="/dashboard" />
    <InnerLayout.SidebarEntry label="Account" icon={AccountIcon} href="/account" />
    <InnerLayout.SidebarEntry label="Multi Armed Bandit" icon={GameIcon} href="/multi_armed_bandit_game" />
    <InnerLayout.SidebarEntry label="Kelly Betting Game" icon={GameIcon} href="/kelly_betting_game" />
    <InnerLayout.Body>
      {props.children}
    </InnerLayout.Body>
  </InnerLayout>
}
