import React from "react";
import { Form, Card, Row, Container, Col, Button, ProgressBar, Table, Modal } from 'react-bootstrap';
import update from 'immutability-helper';
import { WidgetWrapper, Section, BrandedComponentProps } from '@innexgo/common-react-components';
import DashboardLayout from '../components/DashboardLayout';

import { ArticleData, articleDataViewPublic } from '../utils/api';
import { AuthenticatedComponentProps } from '@innexgo/auth-react-components';

import { randn_bm } from '../utils/normal';

import Plot from 'react-plotly.js';
import { Formik, ErrorMessage, FormikHelpers, FormikErrors, isNaN } from "formik";

type ArcadeGame = {
  // Integer number of tickets it takes to operate
  ticketsNeeded: number,
  // where the normal distribution is centered at
  mean: number,
  // standard deviation of the normal distribution
  stddev: number,
}

type HistoryEntry = {
  indexPulled: number,
  payout: number,
}

type GameState = {
  totalTickets: number,
  // list of all the arcade games
  machines: ArcadeGame[]
  // list of arcade games used
  history: HistoryEntry[]
}



function getTicketsUsed(state: GameState) {
  let tickets = 0;
  for (const entry of state.history) {
    tickets += state.machines[entry.indexPulled].ticketsNeeded;
  }
  return tickets;
}

function getScore(state: GameState) {
  let score = 0;
  for (const entry of state.history) {
    score += entry.payout;
  }
  return score;
}

type GameProps = {
  state: GameState,
  onSelect: (e: HistoryEntry) => void,
  onReset: () => void,
  onNewGame: () => void,
}

function BanditGame(props: GameProps) {
  const score = getScore(props.state);
  const ticketsLeft = props.state.totalTickets - getTicketsUsed(props.state);
  const percent = 100 * getTicketsUsed(props.state) / props.state.totalTickets;

  return <>
    <Modal
      centered
      backdrop="static"
      keyboard={false}
      show={props.state.machines.filter(x => !(x.ticketsNeeded > ticketsLeft)).length === 0}
    >
      <Modal.Header><h5>Game Over!</h5></Modal.Header>
      <Modal.Body>
        <h6 className="mt-3">Final Score</h6>
        <p className="text-success"><b>{score} points</b></p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={props.onReset}>Reset Game</Button>
        <Button variant="primary" onClick={props.onNewGame}>Start New Game</Button>
      </Modal.Footer>
    </Modal>
    <WidgetWrapper title="Multi Armed Bandit Game">
      <span>Shows basic information about this game.</span>
      <Row>
        <Col md={6}>
          <h5 className="mt-3">Tickets Left</h5>
          <p className="text-danger"><b>{ticketsLeft} tickets left</b></p>
          <ProgressBar className="mt-3" variant="danger" now={Math.max(percent, 15)} label={`${Math.ceil(percent)}% tickets used`} />
          <h5 className="mt-3">Points So Far</h5>
          <p className="text-success"><b>{score} points</b></p>
        </Col>
        <Col md={6}>
          <h6>Explanation</h6>
          <div>
            In the multi-armed-bandit problem, you're faced with a set of arcade games.
            You have a fixed amount of time to get as much points as you can out of them.
            However, you don't know what the acade games pay out.
            Additionally, the arcade games all take a different amount of tickets to operate.
            Some games are more worth it than others.
            The challenge is to strike a balance between exploring the payoffs of the various games
            and earning points by exploiting the games you think are good.
          </div>
        </Col>
      </Row>
    </WidgetWrapper>
    <div
      className="mt-5"
      style={{ display: "flex", flexWrap: "wrap" }}
    >
      {
        props.state.machines.map((m, i) =>
          <Card key={i} className="m-2">
            <Card.Body>
              <Card.Title>Arcade Game {i + 1}</Card.Title>
              <Card.Text>Tickets Needed: {m.ticketsNeeded}</Card.Text>
              <Card.Text>Uses: {props.state.history.filter(x => x.indexPulled == i).length}</Card.Text>
              <Button
                variant="primary"
                children="Operate"
                disabled={m.ticketsNeeded > ticketsLeft}
                onClick={() => {
                  while (true) {
                    let payout = Math.floor(m.mean + m.stddev * randn_bm());
                    if (payout >= 0 && payout <= 100) {
                      props.onSelect({
                        payout,
                        indexPulled: i,
                      });
                      break;
                    }
                  }
                }}
              />
            </Card.Body>
            <Plot
              className="mx-2"
              data={[
                {
                  type: 'histogram',
                  xbins: {
                    start: 0,
                    end: 100,
                    size: 5,
                  },
                  x: props.state.history.filter(x => x.indexPulled == i).map(x => x.payout),
                },
              ]}
              layout={{
                xaxis: {
                  title: { text: 'Payout', },
                  range: [0, 100]
                },
                yaxis: { title: { text: 'Count', } },
                width: 300,
                height: 100,
                margin: { b: 30, l: 40, r: 10, t: 10 },
              }}
              config={{ staticPlot: true }}
            />
          </Card>
        )
      }
    </div>
    <div className="mt-5">
      <h5>History</h5>
      <Table bordered striped hover>
        <thead>
          <tr><th>Arcade Game</th><th>Payout</th><th>Tickets Needed</th></tr>
        </thead>
        <tbody>
          {
            props.state.history.map((h, i) => <tr key={i}>
              <td>Game {h.indexPulled + 1}</td>
              <td>Points: {h.payout}</td>
              <td>{props.state.machines[h.indexPulled].ticketsNeeded} tickets</td>
            </tr>
            ).reverse()
          }
        </tbody>
      </Table>
    </div>
    <div className="mt-5">
      Sound Effect Credits: <a href="https://www.freesoundslibrary.com/cash-register-sound-effect/" children="source" />
    </div>
  </>
}

type GameSetupProps = {
  onInitialize: (state: GameState) => void,
}

function GameSetup(props: GameSetupProps) {
  type GameSetupValue = {
    tickets: number,
    machines: number,
  }

  const onSubmit = (values: GameSetupValue, fprops: FormikHelpers<GameSetupValue>) => {
    let hasError = false;
    let errors: FormikErrors<GameSetupValue> = {};

    if (values.tickets % 1 !== 0 || isNaN(values.tickets)) {
      errors.tickets = "Value must be integer";
      hasError = true;
    }
    if (values.tickets <= 0 || values.tickets > 300) {
      errors.tickets = "Value out of bounds";
      hasError = true;
    }

    if (values.machines % 1 !== 0 || isNaN(values.machines)) {
      errors.machines = "Value must be integer";
      hasError = true;
    }
    if (values.machines <= 0 || values.machines > 100) {
      errors.machines = "Value out of bounds";
      hasError = true;
    }

    fprops.setErrors(errors);
    if (hasError) {
      return;
    }

    let machines: ArcadeGame[] = [];
    for (let i = 0; i < values.machines; i++) {
      machines.push({
        ticketsNeeded: Math.ceil(Math.random() * 10),
        mean: Math.ceil(Math.random() * 100),
        stddev: Math.random() * 25
      });
    }

    props.onInitialize({
      totalTickets: values.tickets,
      machines,
      history: []
    });
  }
  return <div className="h-100 w-100 d-flex">
    <Card className="mx-auto my-auto col-md-6">
      <Card.Body>
        <Card.Title>Multi Armed Bandit Game Setup</Card.Title>
        <Formik<GameSetupValue>
          onSubmit={onSubmit}
          initialValues={{
            tickets: 50,
            machines: 10,
          }}
        >
          {(fprops) => <Form noValidate onSubmit={fprops.handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Number of Arcade Games</Form.Label>
              <Form.Control
                type="number"
                onChange={fprops.handleChange}
                value={fprops.values.machines}
                name="machines"
                isInvalid={!!fprops.errors.machines}
              />
              <Form.Control.Feedback type="invalid">{fprops.errors.machines}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Number of Tickets</Form.Label>
              <Form.Control
                type="number"
                onChange={fprops.handleChange}
                value={fprops.values.tickets}
                name="tickets"
                isInvalid={!!fprops.errors.tickets}
              />
              <Form.Control.Feedback type="invalid">{fprops.errors.tickets}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Button type="submit" style={{ display: "block" }}>
                Start Game!
              </Button>
              <Form.Text muted>Good Luck!</Form.Text>
            </Form.Group>
          </Form>
          }
        </Formik>
      </Card.Body>
    </Card>
  </div>
}


function MultiArmedBanditGame(props: AuthenticatedComponentProps) {

  let [state, setState] = React.useState<GameState | null>(null);

  return <DashboardLayout {...props}>
    <Container className="px-4 py-4">
      {
        state === null
          ? <GameSetup onInitialize={(state) => setState(state)} />
          : <BanditGame
            state={state}
            onSelect={entry => setState(update(state, { history: { $push: [entry] } }))}
            onReset={() => setState(update(state, { history: { $set: [] } }))}
            onNewGame={() => setState(null)}
          />
      }
    </Container>
  </DashboardLayout>
}

export default MultiArmedBanditGame;
